var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "organ-report-p" },
    _vm._l(_vm.reportList, function (item) {
      return _c(
        "div",
        { key: item.title, staticClass: "report-p-multi" },
        [
          _c("panelTable", {
            attrs: {
              title: item.title,
              type: "insurance",
              "table-list": item.column ? item.column : [],
            },
          }),
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }